<template>
  <div class="require-job">
    <div class="require-job__container">
      <div class="require-job__content">
        <h2 class="require-job__title">{{ dataJob.name }}</h2>
        <div class="banner-job__benefit">
          <div class="banner-job__item">
            <img alt="moneyIcon" :src="moneyIcon" />
            <div class="job__item">
              <span class="job__item-title">{{ detailJobs.salary }}</span>
              <span>{{ dataJob.salary }}</span>
            </div>
          </div>
          <div class="banner-job__item">
            <img alt="middleIcon" :src="middleIcon" />
            <div class="job__item">
              <span class="job__item-title">{{ detailJobs.experience }}</span>
              <span>{{ dataJob.number_experience }}</span>
            </div>
          </div>
          <div class="banner-job__item">
            <img alt="hoursIcon" :src="hoursIcon" />
            <div class="job__item">
              <span class="job__item-title">{{ detailJobs.level }}</span>
              <span>{{ job_position?.join(", ") }}</span>
            </div>
          </div>
        </div>
        <div class="require-job__item">
          <p style="font-weight: 700; font-size: 24px">Chi tiết:</p>
          <div v-html="dataJob.description" />
          <p style="font-weight: 700; font-size: 24px">Yêu cầu:</p>
          <div v-html="dataJob.requirement" />
          <p style="font-weight: 700; font-size: 24px">Quyền lợi:</p>
          <div v-html="dataJob.benefit" />

          <div
            :key="field.key"
            v-for="field in JSON.parse(dataJob.json_add_field || '{}')"
          >
            <p style="font-weight: 700; font-size: 24px">{{ field.label }}</p>
            <div v-html="field.value"></div>
          </div>

          <p style="font-weight: 700; font-size: 24px; margin: 0">
            Vị trí tuyển dụng:
          </p>
          <ul>
            <li style="" :key="jp.id" v-for="jp in dataJob.jobjobpositions">
              {{ jp.job_position.name }}
            </li>
          </ul>

          <div style="display: flex; gap: 8px; align-items: center">
            <p style="font-weight: 700; font-size: 24px; margin: 0">
              Ngày hết hạn:
            </p>
            <span
              style="font-size: 24px"
              v-html="new Date(dataJob.recruitment_period).toLocaleDateString()"
            ></span>
          </div>

          <div style="display: flex; gap: 8px; align-items: center">
            <p style="font-weight: 700; font-size: 24px; margin: 0">
              Ngày onboard:
            </p>
            <span
              style="font-size: 24px"
              v-html="new Date(dataJob.onboard_date).toLocaleDateString()"
            ></span>
          </div>
        </div>
        <!-- <a href="#form-mail"> -->
        <button
          v-on:click="focusInput(dataJob.id, dataJob.name, true)"
          class="button"
        >
          Ứng tuyển ngay
        </button>
        <!-- </a> -->
      </div>
      <div class="wrapper__form">
        <div class="require-job__form">
          <FormMail
            ref="formMailParent"
            :dataMail="contact.dataFormMail"
            :skills="skills"
            :nameJob="dataJob.name"
          ></FormMail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormMail from "../../../FormMail.vue";
import axios from "axios";

const TRACKING_KEY = process.env.VUE_APP_TRACKING_KEY;

export default {
  name: "Require-job",
  data() {
    return {
      contact: this.$t("contact"),
      detailJobs: this.$t("detailJobs"),
      bannerDetailImg: "/images/recruitment/banner-detail.png",
      middleIcon: "/images/icons/recruitment/timer.svg",
      moneyIcon: "/images/icons/recruitment/dollar-circle.svg",
      hoursIcon: "/images/icons/recruitment/Icons.svg",
      job_position: [],
    };
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    async focusInput(id, name, isClick) {
      this.$refs.formMailParent.$refs.refInput.focus();
      if (isClick) {
        await axios.post("https://alink.wiki/api/v1/events", {
          project_token: TRACKING_KEY,
          category: `job_detail-${name}-${id}`,
          action: "click_btn_ung_tuyen_bottom",
        });
      }
    },
  },
  components: {
    FormMail,
  },
  props: {
    dataJob: Object,
    skills: Array,
  },
  watch: {
    lang() {
      this.contact = this.$t("contact");
    },
    dataJob: {
      handler(value) {
        if (value) {
          this.job_position = this.dataJob?.jobjobpositions?.map(
            (job) => job.job_position.name
          );
        }
      },
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
};
</script>

<style lang="scss" scroped>
.require-job {
  position: relative;
  width: 100%;
  padding-top: 50px;
  font-size: 16px;
  margin-top: 50px;

  .require-job__container {
    width: 90%;
    margin: auto;
    display: flex;
    gap: 4%;
  }

  .require-job__content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 56%;

    .require-job__title {
      color: #1044b4;
      font-weight: 700;
      font-size: 40px;
      padding: 0;
    }

    .banner-job__benefit {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
      min-height: 96px;
      background-color: #edf7ff;
      border-radius: 10px;
      padding: 10px;
      flex-wrap: wrap;

      .banner-job__item {
        display: flex;
        gap: 15px;
        align-items: center;
        padding: 16px;
        flex: 1;
        min-width: 200px;

        & > img {
          height: 42px;
          width: 42px;
          object-fit: cover;
        }

        & > span {
          font-weight: 700;
          font-size: 18px;
          color: var(--grey-text);
        }

        .job__item {
          display: flex;
          flex-direction: column;

          .job__item-title {
            font-weight: 700;
            font-size: 18px;
            color: var(--grey-text);
          }
        }
      }

      .banner-job__image {
        width: 50%;
        height: 100%;
        clip-path: polygon(20% 0, 100% 0%, 100% 100%, 20% 100%, 0% 50%);

        & > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .button {
      border-radius: 20px;
      margin-bottom: 32px;
      width: 40%;
      padding: 8px 0 !important;
      background-color: transparent !important;
      border: 1px solid var(--blue-text);
      color: var(--blue-text);
      font-weight: 600;
      text-align: center;
      cursor: pointer;
    }

    button:active {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
      outline: none;
    }
  }

  .require-job__item {
    margin-bottom: 0px;
    padding: 24px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

    h3 {
      font-weight: 700;
      font-size: 21px;
      color: var(--black-text);
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: var(--grey-text);
      line-height: 1.42;
    }
  }

  .wrapper__form {
    width: 36%;
  }

  .require-job__form {
    position: sticky;
    right: 5%;
    width: 100%;
    height: max-content;
    padding: 8px 24px;
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    top: 100px;
  }

  @media (max-width: 1024px) {
    .require-job__container {
      flex-direction: column;
    }

    .require-job__title {
      margin-top: 80px;
      text-align: center;
    }

    .wrapper__form,
    .require-job__content {
      width: 100%;
      .button {
        width: 100%;
      }
    }

    .require-job__form {
      padding: 16px 24px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 0;
    .require-job__title {
      margin-top: 80px;
      text-align: center;
    }

    .banner-job__benefit {
      justify-content: flex-start !important;
      flex-wrap: wrap;
    }

    .banner-job__image {
      display: none;
    }

    .banner-job__content {
      width: 100%;

      h2 {
        font-size: 32px;
      }
    }

    .require-job__content {
      .button {
        width: 100%;
      }
    }
  }
}
</style>
