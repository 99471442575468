<template>
  <div>
    <!-- <BannerJob :data="recruiment" /> -->
    <RequireJob :dataJob="recruiment" :skills="skills" />
    <BenefitPackage />
    <Opportunities :data="item_filter" />
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import BannerJob from './Job/BannerJob.vue';
import BenefitPackage from './Job/BenefitPackage.vue';
import Opportunities from './Job/Opportunities.vue';
import RequireJob from './Job/RequireJob.vue';
import axios from 'axios';

const Footer = () => import('../../../views/FooterNew.vue');

const HRM_URL = process.env.VUE_APP_API_URL;
const TRACKING_KEY = process.env.VUE_APP_TRACKING_KEY;

export default {
  name: 'DetailJob',
  data() {
    return {
      recruiment_detail: this.$t('recruiment'),
      recruiment: {},
      content: null,
      id_job: 0,
      value: '',
      item_filter: [],
      skills: []
    };
  },
  components: {
    // BannerJob,
    RequireJob,
    BenefitPackage,
    Opportunities,
    Footer
  },
  mounted() {
    this.id_job = this.$route.params.id;
    this.handleGetJobDetail(this.$route.params.id);
    this.handleGetSkills();
    this.handleGetJob();

    // this.recruiment = this.$t('recruiment').jobOpenings.listjob.filter(
    //   (job) => job.id.toString() === this.id_job
    // )[0];
    // this.item_filter = this.job.filter(
    //   (job) => job.id.toString() !== this.id_job
    // );
  },
  methods: {
    async handleGetJobDetail(id) {
      const res = await axios.get(`${HRM_URL}/jobs/${id}`);
      this.recruiment = res.data.data;
    },
    async handleGetSkills() {
      const res = await axios.get(`${HRM_URL}/skills`);
      this.skills = res.data.data;
    },
    async handleGetJob() {
      const res = await axios.get(`${HRM_URL}/jobs`);
      this.item_filter = res.data.data
        .filter((job) => job.status)
        .filter((job) => job.id.toString() !== this.id_job);
    },
    async postEventLoad(id, name) {
      await axios.post('https://alink.wiki/api/v1/events', {
        project_token: TRACKING_KEY,
        category: `job_detail-${name}-${id}`,
        action: 'onLoad'
      });
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
      page: (state) => state.page.page
    })
  },
  watch: {
    lang() {
      this.recruiment_detail = this.$t('recruiment');
      this.id_job = this.$route.params.id;
      // this.recruiment = this.$t('recruiment').jobOpenings.listjob.filter(
      //   (job) => job.id.toString() === this.id_job
      // )[0];
      // this.item_filter = this.$t('recruiment').jobOpenings.listjob.filter(
      //   (job) => job.id.toString() !== this.id_job
      // );
    },
    recruiment: {
      handler(value) {
        if (value) {
          this.postEventLoad(value.id, value.name);
        }
      }
    }
  }
};
</script>

<style scoped></style>
